import { BehaviorSubject } from 'rxjs';

//import config from 'config';
import { handleResponse } from '../_helpers';
import { reach } from 'yup';
import { apiLogin, apiVerifyAuth } from '../_components';
import axios from 'axios';
import Swal from 'sweetalert2';

const currentLoginSubject = new BehaviorSubject(JSON.parse(sessionStorage.getItem('currentLogin')));

export const authenticationService = {
    login,
    logout,
    currentLogin: currentLoginSubject.asObservable(),
    get currentLoginValue() { return currentLoginSubject.value },
    addLogin,
};

async function login(username, password) {
    const requestOptions = {
        method: 'get',
        body: { username: username, password: password }
    };
    var dataPost = {
        username: username,
        password: password
    }
    var con = false;
    const requestOptionsLogin = { timeout: 100000 };
    await axios.post(apiLogin, dataPost, requestOptionsLogin).then(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        if (user.status == 200) {
            sessionStorage.setItem('currentLogin', JSON.stringify(user));
            currentLoginSubject.next(user);
            con = true;
        } else if (user.status == 204) {
            con = Promise.reject("Username atau kata sandi yang Anda masukkan salah.");
        } else if (user.status == 422) {
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Perhatian!',
            //     text: 'Anda belum registrasi atau username dan password ada yang salah.',
            // })
            con = Promise.reject("Username atau kata sandi yang Anda masukkan salah.");
            console.log('error')
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Perhatian!',
                text: 'Maaf, saat ini sistem kami sedang sibuk. Coba ulangi beberapa saat lagi.',
            })
        }
    })
        .catch(err => {
            const error = 'Kesalahan dari server';
            con = Promise.reject(error);
        });
    return con;
}

function logout() {
    // remove user from local storage to log user out
    sessionStorage.clear();
    currentLoginSubject.next(null);
}

function addLogin(data) {
    currentLoginSubject.next(data);
}