import React, { Component } from 'react';
import { HashRouter, Route, Switch, BrowserRouter } from 'react-router-dom';
import './scss/style.scss';
import { PrivateRoute, apiVerifyAuth } from './_components';
import { authenticationService } from './_services';
import { history } from './_helpers';
import { socket_io } from './_services';

const currentUser  = authenticationService.currentUserValue != null ? true : false;

socket_io.on('show_notification', function (data) {
  console.log(data)
  showDesktopNotification(data.data.title, data.data.message, data.data.icon, data.data.tag);
});

//Notification
var Notification = window.Notification || window.mozNotification || window.webkitNotification;
Notification.requestPermission(function (permission) {
});
/**
 * Request Browser Notification Permission 
 * @type Arguments
 */
function requestNotificationPermissions() {
  if (Notification.permission !== 'denied') {
    Notification.requestPermission(function (permission) {
    });
  }
}

function showDesktopNotification(message, body, icon, tag) {
  var timeout = 4000;
  var sound = null;
  var instance = new Notification(
    message, {
    body: body,
    icon: icon,
    sound: sound
  }
  );
  instance.onclick = function () {
    // Something to do
    console.log('ClikedNotif' + tag);

    // This looks to see if the current is already open and
    // focuses if it is
    window.open('http://localhost:3001', '_blank');
  };
  instance.onerror = function () {
    // Something to do
  };
  instance.onshow = function () {
    // Something to do
  };
  instance.onclose = function () {
    // Something to do
  };
  // if (sound) {
  //   instance.sound;
  // }
  setTimeout(instance.close.bind(instance), timeout);
  return false;
}

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Landing = React.lazy(() => import('./views/pages/landing/Landing'));
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

if(currentUser){
  console.log("current user ada")
  socket_io.emit("login", authenticationService.currentUserValue.data.token);
}

class App extends Component {

  render() {
    return (
      <HashRouter history={history}>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/landing" name="Landing Page" render={props => <Landing {...props} />} />
            <Route exact path="/login" name="Login MDSp" render={props => <Login {...props} />} />
            <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <PrivateRoute path="/" name="Home" />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
