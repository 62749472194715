let SSO_URL = process.env.REACT_APP_SSO;
let LOGIN_URL = process.env.REACT_APP_URL_LOGIN_GLOBAL;

let URL = process.env.REACT_APP_URL_GLOBAL;
// let URL = process.env.REACT_APP_URL_LOKAL;
// let URL            = 'https://api.atikan.tikomdik-disdikjabar.id/manajemen';
let URL_SOCKET = 'https://api-atikan.jabarprov.go.id'

//Login
export const apiLogin = URL + '/pengajuan/mutasi/verify';
export const apiVerifyAuth = URL + '/pengajuan/mutasi/verify';
export const getUser = URL + '/getUserLogin';

//NOTIF
export const socketIOURL = URL_SOCKET;
export const notifKurikulum = URL + '/notification?is_viewed=0&tag=laporan_wali';

//REF
export const getRefAgama = URL + '/pengajuan/ref/agama';
export const getRefBentukPend = URL + '/pengajuan/ref/bentuk_pendidikan';
export const getRefJurusanSMA = URL + '/pengajuan/ref/jurusan';
export const getRefJurusanSMK = URL + '/pengajuan/ref/jurusan/pk';

//PERMOHONAN MUTASI
export const getFile = URL + '/pengajuan/mutasi/persyaratan-file?';
export const getWilayah = URL + '/pengajuan/mutasi/wilayah?';
export const getSekolah = URL + '/pengajuan/mutasi/sekolah/'; //020815/15/15052515
export const getDetailSekolah = URL + '/custom/sekolah/detail/'; //20227931/15052515
// export const getAlasan               = URL + '/pengajuan/mutasi/alasan';
export const getConfig = URL + '/pengajuan/mutasi/config?';
export const getAlasan = URL + '/pengajuan/mutasi/persyaratan-file';
export const postPermohonan = URL + '/pengajuan/mutasi'; //20227931/15052515
export const patchPD = URL + '/pengajuan/mutasi/pd';
export const postFile = URL + '/pengajuan/mutasi/berkas';
export const postRegister = URL + '/pengajuan/mutasi/registrasi';

//DASHBOARD
export const apiPatchPassword = 'https://api.atikan.tikomdik-disdikjabar.id/sso/auth/password?password=';
export const getRefSLB = URL + '/kurikulum/rekap/slb/dashboard';