//import config from 'config';
import { authHeader, handleResponse } from '../_helpers';
import axios from 'axios'
import { apiJurusanSp } from '../_components'
import swal from 'sweetalert'

export const userService = {
    getAll,
    getData,
    postData
};

function getAll() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`http://localhost:4000/users`, requestOptions).then(handleResponse);
}


//GET DATA
function getData(apiGet) {
    const requestOptions = {timeout: 50000, headers: authHeader() };
    return axios.get(apiGet, requestOptions)
        .then(user =>{
            return user;
        })
        .catch(error => {
            if (!error.response) {
                swal("Terjadi Kesalahan pada Server", "Please check your internet connection.", "warning");
                //console.log("Please check your internet connection.");
            }
    
            return Promise.reject(error)
        });
}

//POST DATA
function postData(apiPost, dataPost) {
    const requestOptions = {timeout: 50000, headers: authHeader() };
    return axios.post(apiPost, dataPost, requestOptions)
        .then(user =>{
            return user;
        })
        .catch(error => {
            if (!error.response) {
                swal("Terjadi Kesalahan", "Please check your internet connection.", "warning");
                //console.log("Please check your internet connection.");
            }
    
            return Promise.reject(error)
        });
}

export default userService